import { useEffect, useState/*, useCallback*/ } from 'react';
import Axios from 'axios';
import { Element, Link as Ir } from 'react-scroll';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
    type: tipo,
    title: titulo
  });
}

function App() {

  //const [background, setBackground] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalServicios, setModalServicios] = useState(false);
  const [servicio, setServicio] = useState("");
  const [index, setIndex] = useState(0);
  const [verMenu, setVerMenu] = useState('no_ver_menu');

  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [mensaje, setMensaje] = useState("");

  const [datos, setDatos] = useState(false);
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [duracion, setDuracion] = useState("");
  const [estado, setEstado] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [cp, setCP] = useState("");
  const [tipo, setTipo] = useState("");
  const [personas, setPersonas] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      //setBackground(true)
      setScroll(window.scrollY)
    });

    return () => {
      if(window.location.href.substr(0, 5)==='http:' && window.location.href!=='http://localhost:3000/'){
        window.location.href = window.rutas;
      }
      //window.removeEventListener("scroll", (e) => setBackground(false));
    };
  }, [/*background*/]);

  //let suma = 0

  return (
    <div>
      <div className="icono_menu" onClick={() => setVerMenu('ver_menu')}><img src="img/menu.svg" title="Menú" alt="Menú"/></div>
      <div className="cerrar_menu" id={verMenu} onClick={() => setVerMenu('no_ver_menu')}></div>
      <div className="menu" id={scroll>100 && window.screen.width>750 ? 'menu' : window.screen.width>750 ? null : verMenu} data-aos="fade-down">
        <div className="max_width_menu">
          <div className="menu_flex">
            <div className="logo_flex">
              <img src="img/logo.svg" title={window.nombre} alt={window.nombre}/>
              <div>
                <h7>{window.nombre}</h7>
                <br/>
                AGENCIA DE EVENTOS
              </div>
            </div>
            <div className="enlaces">
              <Ir to="inicio" className="mi_enlace">Inicio</Ir>
              <Ir to="nosotros" className="mi_enlace">Nosotros</Ir> 
              <Ir to="galeria" className="mi_enlace">Galería</Ir>
              <Ir to="servicios" className="mi_enlace">Servicios</Ir>
              <Ir to="contacto" className="mi_enlace">Contacto</Ir>
            </div>
            <div className="redes">
              {
                window._my_redes.map((item, i) => 
                  <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <Element name="inicio"></Element>
      <Carousel fade controls={false} interval={3000} pause={false} indicators={false}>
        {
          window._my_carousel.map((item, i) =>
            <Carousel.Item key={i} className="car_img" style={{backgroundImage: `url(${item.foto})`}}>
              <div className="car_fondo">
                <div>
                  {item.texto ? <h8>{item.texto}</h8> : null}
                  {item.titulo ? <h9>{item.titulo}</h9> : null}
                  {item.www ? <a href={item.www} className="car_url" target="new">{item.www_decir}</a> : null}
                  {item.ir ? <Ir to={item.ir} className="car_url">{item.ir_decir}</Ir> : null}
                </div>
              </div>
            </Carousel.Item>
          )
        }
      </Carousel>
      <div id="redes">
        <div className="redes">
          {
            window._my_redes.map((item, i) => 
              <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
            )
          }
        </div>
      </div>
      <Element name="nosotros"></Element>
      <div className="fondo_nosotros">
        <div className="max_width padding_width">
          <div className="flex_between alinear">
            <div className="nosotros_texto" data-aos="fade-right">
              <h11>Mezclando la mejor música desde {window.anos}</h11>
              <h12>CONÓCENOS</h12>
              <div className="justificar">
                Somos un equipo con más de {window.hoy.getFullYear()-2010-1} años de experiencia, que nos emociona y apasiona lo que hacemos. Que pasamos de un hobby a un servicio de calidad y competente.
              </div>
              <br/>
              <h12>¿Porque Nosotros?</h12>
              <ul className="lista">
                <li>Más de {window.hoy.getFullYear()-2010-1} años de experiencia</li>
                <li>Puntualidad y profesionalismo</li>
                <li>Equipo de audio profesional</li>
                <li>Apegados 100% al concepto del evento</li>
                <li>Amplio repertorio musical (cualquier género o época)</li>
                <li>Sin mixes pregrabados o con “efectitos”</li>
              </ul>
            </div>
            <div className="nosotros_img" data-aos="fade-left">
              <div className="box_imagen">
                <img src="img/nosotros.jpg" title={window.nombre} alt={window.nombre}/>
              </div>
            </div>
          </div>
          <Element name="galeria"></Element>
        </div>
      </div>
      <div className="galeria" >
        {
          window._my_galeria.map((item, i) =>
            <div key={i} className="my_galeria" onClick={() => {
              setModal(true)
              setIndex(item.id)
            }}>
              <img src={item.foto} title={item.titulo} alt={item.titulo}/>
              <div className="hover_galeria">
                <div className="cajas_galeria">
                  {item.titulo}
                  <div className="linea_galeria"/>
                  <h13>{item.fecha}</h13>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div id="cl"></div>
      <Element name="servicios"></Element>
      <div className="fondo_servicios">
        <div className="max_width padding_width">
          <center>
            <h11>Nuestros servicios</h11>
            <h14>Conoce los paquetes que tenemos para tu evento</h14>
          </center>
          <div className="servicios margin_servicios">
            {
              window._my_servicios.map((item, i) => 
                <div className="my_servicio" data-aos="fade-down" key={i}>
                  <div className="box_servicio">
                    <div className="caja_servicio">
                      <div className="servicio_relative">
                        <img src={item.foto} title={item.titulo} alt={item.titulo}/>  
                        <div className="servicio_contrato">
                          <a href={`${window.whats_contratar}%20${item.titulo}`} target="new">Contratar</a>
                        </div>
                      </div>
                      <div className="servicio_info">
                        <center>
                          <h15>{item.titulo}</h15>
                          <br/>
                          ({item.leyenda})
                        </center>
                        <ul className="lista_servicios">
                          {
                            item.lista.slice(0, 5).map((lista, n) =>
                              <li key={n}>{lista}</li>
                            )
                          }
                          <li>y más...</li>
                        </ul>
                        <div className="precio_servicio">Desde <h16>{window.moneda.format((item.costo))}</h16></div>
                        <div className="servicio_mas" onClick={() => {
                          setModalServicios(true)
                          setServicio(item.id)
                        }}>
                          Ver más detalles
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <div id="cl"></div>
        </div>
      </div>
      <Element name="contacto"></Element>
      <div className="fondo_contacto">
        <div className="max_width padding_width">
          <center>
            <h11>Contacto</h11>
            <h12>Cuéntanos de que trata tu evento</h12>
          </center>
          <form data-aos="fade-down">
            <div className="campos_formulario">
              <input type="text" name="nombre" onChange={(e) => setNombre(e.target.value)} placeholder="Nombre:" value={nombre} required/>
              <input type="text" name="correo" onChange={(e) => setCorreo(e.target.value)} placeholder="Correo:" value={correo} required/>
              <input type="text" name="telefono" onChange={(e) => setTelefono(e.target.value)} placeholder="Teléfono:" value={telefono} required/>
            </div>
            <textarea name="mensaje" id="area" onChange={(e) => setMensaje(e.target.value)} placeholder="Mensaje:" value={mensaje} required/>
            <div className="datos"><div className={datos===true ? 'datos_hover' : null} onClick={() => setDatos(!datos)}>+ Datos del evento</div></div>
            {
              datos===true ?
                <div className="mi_evento">
                  <div className="campos_formulario">
                    <div>
                      Fecha:
                      <input type="date" name="fecha" onChange={(e) => setFecha(e.target.value)} placeholder="dd/mm/aaaa" value={fecha}/>
                    </div>
                    <div>
                      Hora:
                      <input type="time" name="hora" onChange={(e) => setHora(e.target.value)} placeholder="00:00" value={hora}/>
                    </div>
                    <div>
                      Duración (hrs):
                      <select name="duracion" onChange={(e) => setDuracion(e.target.value)}>
                        <option value="" selected={duracion==="" ? true : false}></option>
                        {
                          window._my_duracion.map((item, i) =>
                            <option value={item} key={i} selected={item===duracion ? true : false}>{item}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className="campos_formulario">
                    <div>
                      Estado:
                      <select name="estado" onChange={(e) => setEstado(e.target.value)}>
                        <option value="" selected={duracion==="" ? true : false}></option>
                        {
                          window._my_estado.map((item, i) =>
                            <option value={item.estado} key={i} selected={item.estado===estado ? true : false}>{item.estado}</option>
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Municipio / Delegación:
                      <select name="municipio" onChange={(e) => setMunicipio(e.target.value)}>
                        <option value="" selected={municipio==="" ? true : false}></option>
                        {
                          window._my_estado.filter(p => p.estado===estado).map(esta =>
                            esta.municipios.map((item, i) =>
                              <option value={item} key={i} selected={item===municipio ? true : false}>{item}</option>
                            )
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Código postal:
                      <input type="number" name="cp" onChange={(e) => setCP(e.target.value)} placeholder="Solo número" value={cp}/>
                    </div>
                  </div>
                  <div className="campos_formulario_dos">
                    <div>
                      Tipo de servicio:
                      <select name="tipo" onChange={(e) => setTipo(e.target.value)}>
                        <option value="" selected={tipo==="" ? true : false}></option>
                        {
                          window._my_tipo.map((item, i) =>
                            <option value={item} key={i} selected={item===tipo ? true : false}>{item}</option>
                          )
                        }
                      </select>
                    </div>
                    <div>
                      Número aproximado de personas:
                      <select name="personas" onChange={(e) => setPersonas(e.target.value)}>
                        <option value="" selected={personas==="" ? true : false}></option>
                        {
                          window._my_personas.map((item, i) =>
                            <option value={item} key={i} selected={item===personas ? true : false}>{item}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                </div>
              :
                null
            }
            <center><button onClick={(e) => {
              e.preventDefault();
              if(nombre===''){
                alerta('error','Ingresa tu nombre completo');
              }else if(correo===''){
                alerta('error','Ingresa tu correo electrónico');
              }else if(window.validarMail(correo)===false){
                alerta('error','Ingrese un correo electrónico valido');
              }else if(mensaje===''){
                alerta('error','Escribe un mensaje');
              }else{
                window.H5_loading.show();
                var params = new URLSearchParams();
                params.append('nombre', nombre);
                params.append('correo', correo);
                params.append('mensaje', mensaje);
                params.append('telefono', telefono);
                //params.append('asunto', asunto);
                
                Axios.post(`${window.envia}`, params)
                  .then(response => {
                    if(response.data.elError===1){
                      alerta('success',response.data.mensaje)
                      setNombre("");
                      setCorreo("");
                      setTelefono("");
                      setMensaje("");
                    }else{
                      alerta('error',response.data.mensaje)
                    }
                  })
                  .catch(error => {
                    //window.alerta('error','LOLERO'+error);
                    console.log(error)
                  })
                  .then(function() {
                    window.H5_loading.hide();
                  })
              }
            }}>Enviar mensaje</button></center>
          </form>
        </div>
      </div>
      <div className="pie">
        <div className="max_width padding_pie">
          <div className="mi_pie alinear">
            <div className="flex_start alinear">
              <Ir to="inicio" className="subir">➤</Ir>
              {window.nombre} © {window.ano}
            </div>
            <div>
              <div className="flex_end alinear">
                <a href={window.whats} target="new" className="flex_tel alinear">
                  <img src="img/con_1.svg" title="Teléfono" alt="Teléfono"/>
                  {window.telefono}
                </a>
                <a href={`mailto:${window.correo}`} target="new" className="flex_tel alinear">
                  <img src="img/con_3.svg" title="Teléfono" alt="Teléfono"/>
                  {window.correo}
                </a>
              </div>
              <div className="para_aviso">
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("preguntas")
                }}>Preguntas frecuentes</div>
                |
                <div onClick={() => {
                  setModalServicios(true)
                  setServicio("aviso")
                }}>Aviso de privacidad</div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <a href={window.whats} target="new" className="whats">
        <img src="img/r_whats-01.svg" title="WhatsApp" alt="WhatsApp"/>
      </a>
      <Modal
        show={modal}
        onHide={() => {
          setModal(false)
        }}
        size="lg"
        className="my-modal"
        centered
      >
        <Modal.Body >
          <Carousel className="slider_modal" defaultActiveIndex={index} interval={1500}>
            {
              window._my_galeria.map((item, i) =>
                <Carousel.Item key={i}>
                  <img src={item.foto} alt="Slider"/>
                </Carousel.Item>
              )
            }
          </Carousel>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalServicios}
        onHide={() => {
          setModalServicios(false)
          servicio(0)
        }}
        size="lg"
        centered
      >
        {
          servicio==='aviso' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Aviso de privacidad</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="at_j">
                  <b>TWO BEAT SOUND</b> así como sus filiales y subsidiarias en adelante, tienen entre sus objetivos, la convicción de proteger los datos personales sensibles, en su caso, proporcionados por la persona física a quien corresponden dichos datos, en adelante <b>“EL TITULAR”</b>, de lo anterior <b>TWO BEAT SOUND</b> ha establecido las siguientes disposiciones para proteger dicha información en la medida de los posible, las cuales pueden cambiar en cualquier momento. Por lo que sugerimos consultarlas periódicamente. 
                  <br/><br/>
                  Los términos que no estén expresamente definidos en estas disposiciones, tendrán el significado que les atribuye a los mismos en la <b>LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES</b> en adelante <b>“LA LEY”.</b> 
                  <ul className="lista_avi">
                    <li>
                      INFORMACIÓN PERSONAL SOLICITADA. <b>TWO BEAT SOUND</b> podrá solicitar información a <b>“EL TITULAR”</b>, con el objeto de poder proporcionarles la mayor experiencia personalizada en el uso de los servicios que se les otorga. El tipo de información que se solicita al titular de los servicios incluye, de manera enunciativa, más no limitativa, el nombre(s), apellido(s), dirección de correo electrónico, teléfono, datos fiscales, dirección de domicilio, entre otros. No toda la información solicitada al usuario al momento de registrarse es obligatoria de proporcionarse, salvo aquella que <b>TWO BEAT SOUND</b> considere conveniente y que así se le haga saber al usuario al momento del registro, por lo que solicitará dicha información cuando considere sea oportuna o necesaria.
                    </li>
                    <li>
                      <b>“EL TITULAR”</b> debe garantizar que los datos personales que proporciona son correctos, completos, veraces y actuales y que se hace responsable de comunicar a su cliente cualquier modificación y que a su vez este tiene la obligación de notificar a <b>TWO BEAT SOUND</b> dichas modificaciones.
                    </li>
                    <li>
                      Los términos “Datos Personales” y “Datos Sensibles” tendrán el significado que se les atribuye en el artículo 3° de <b>“LA LEY”.</b>
                    </li>
                    <li>
                      USO, FINALIDAD Y TRATAMIENTO DE LA INFORMACIÓN. <b>TWO BEAT SOUND</b> utiliza la tecnología más avanzada a su alcance para la protección de la información proporcionada por <b>“EL TITULAR”</b> de los servicios. Esta tecnología encriptado, codifica y previene la intercepción de la información suministrada por el <b>“EL TITULAR”</b>, incluyendo tarjetas de crédito y direcciones de correo electrónico. <b>“EL TITULAR”</b> que facilite sus datos de carácter personal presta su consentimiento expreso para su utilización por <b>TWO BEAT SOUND</b> con la finalidad y realización de actividades propias de su objeto social. <b>“EL TITULAR”</b> que facilite datos de carácter personal queda informado que dicha comunicación se produce en el mismo momento que proporcione los datos a <b>TWO BEAT SOUND</b>
                      <br/><br/>
                      TRATAMIENTO. <b>“EL TITULAR”</b> consciente inequívocamente que los datos personales que nos facilite a lo largo de todas las comunicaciones y que sean incorporados a los ficheros que contengan datos de carácter personal, de los que será responsable <b>TWO BEAT SOUND</b> así como el tratamiento informatizado o no, de los mismos con el fin de que puedan ser utilizados por <b>TWO BEAT SOUND</b> con una finalidad informativa personalizada o no, estadística, así como actividades propias autorizando a <b>TWO BEAT SOUND</b> la extracción, almacenamiento de datos al objeto de mejorar nuestra atención en el desarrollo de los servicios prestados e informarle de nuestros actuales y futuros servicios a través de los diferentes medios que se establecen. <b>TWO BEAT SOUND</b> se reserva el derecho de ceder la información proporcionada por el <b>“EL TITULAR”</b> a cualquiera de sus filiales o subsidiarias, ya sean presentes, o aquella que se constituyan o adquieran en el futuro, a nivel nacional como internacional. De igual modo, por virtud de relación contractual que se establezca entre <b>“EL TITULAR”</b> y <b>“TWO BEAT SOUND”</b>, esta última podrá proporcionar información personal obtenida a las personas físicas o morales que tengan relación con la implementación de la referida relación contractual. Del mismo modo, <b>“EL TITULAR”</b> manifiesta que está consciente en que <b>TWO BEAT SOUND</b> podrá transmitir la información proporcionada a cualquier autoridad judicial autoritaria, administrativa o de cualquier índole que en términos de legislación aplicable le sea solicitado.
                    </li>
                    <li>
                      De igual forma, <b>“EL TITULAR”</b> podrá enviar un comunicado a la misma dirección de correo electrónico, cuando su deseo sea el de acceso y rectificación de cualquier dato en la información proporcionada, conforme a lo previsto en <b>“LA LEY”</b>, <b>“EL TITULAR”</b> tendrá en todo momento el derecho a cancelar sus datos personales conforme a lo previsto en <b>“LA LEY”</b>.
                    </li>
                    <li>
                      PROTECCIÓN DE LA INFORMACIÓN <b>TWO BEAT SOUND</b> expresa su compromiso de proteger la seguridad de la información proporcionada por <b>“EL TITULAR”</b>. Para dichos fines, utilizamos una gran variedad de tecnologías y procedimientos de seguridad, para impedir, en la medida de lo posible, el acceso, uso o divulgación no autorizados. Cuando se transmite la información altamente confidencial (como número de tarjetas bancarias) a través de la red internet, la protegemos mediante encriptación. Será responsabilidad del usuario el que permanezca con carácter confidencial, el uso de cualquier contraseña.
                    </li>
                    <li>
                      CONTACTO. En caso de dudas y/o comentarios en este aviso de privacidad, <b>“EL TITULAR”</b> podrá contactar a <b>TWO BEAT SOUND</b> mediante la dirección electrónica y al teléfono proporcionado. Para lo anterior, deberá hacernos saber fehacientemente los Datos Personales y /o Datos Sensibles que usted desea sean rectificados o revisados, así como el propósito para el cual los aportó y en general cumplir los requisitos mencionados en el art. 29 de <b>“LA LEY”</b>, obteniendo una respuesta sobre el mismo, en un plazo de 15 días hábiles.
                    </li>
                    <li>
                      MARCO LEGAL VIGENTE. <b>“EL TITULAR”</b> se obliga, a respetar toda la normativa legal vigente relacionada con la utilización, gestión y uso de sistemas de información <b>TWO BEAT SOUND</b> extiende el presente aviso de privacidad, de conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales, así como por las normas aplicables a nivel nacional e internacional.
                    </li>
                  </ul>
                  Todos los precios mostrados en el sitio <b>TWO BEAT SOUND</b> pueden variar dependiendo de las necesides del cliente, distacia del lugar del evento, condiciones del lugar del evento, entre otros factores que puedan influir en el costo del servicio.
                </div>
              </Modal.Body>
            </>
          : servicio==='preguntas' ?
            <>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h15>Preguntas frecuentes</h15>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Accordion defaultActiveKey="0">
                  {
                    window._my_preguntas.map((item, i) =>
                      <Accordion.Item eventKey={i.toString()}>
                        <Accordion.Header>{item.id}. {item.pregunta}</Accordion.Header>
                        <Accordion.Body>
                          {item.respuesta}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  }
                </Accordion>
              </Modal.Body>
            </>
          :
            window._my_servicios.filter(p => p.id===servicio).map((item, i) => 
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h15>{item.titulo}</h15>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <img src={item.foto} title={item.titulo} alt={item.titulo} className="img_libro"/>
                  <div className="texto_libro">
                    <b>{item.leyenda}.</b>
                    <br/><br/>
                    {item.descripcion}
                    <br/><br/>
                    <ilumina>
                      Tipo de iluminación {item.iluminacion}
                    </ilumina>
                    <br/><br/>
                    {item.incluye}
                  </div>
                  <div id="cl" className="espacio"></div>
                  <b>Además de te :</b>
                  <br/>
                  <ul className="lista_servicios">
                    {
                      item.lista.map((lista, n) =>
                        <li key={n}>{lista}</li>
                      )
                    }
                  </ul>
                  <div className="texto_libro">
                    No dudes en ponerte en contacto con nosotros para poder platicar de los detalles de tu evento y así poderte recomendar el paquete de DJ para Fiestas que más de ajuste a tus necesidades.
                  </div>
                  <div className="flex_precio_servicio">
                    <div className="precio_servicio mar_precio">Desde <h16>{window.moneda.format((item.costo))}</h16></div>
                    <a href={`${window.whats_contratar}%20${item.titulo}`} target="new">Contratar</a>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="costo_extra">
                    Costo extra por hora {window.moneda.format((item.extra))}
                  </div>
                </Modal.Footer>
              </>
          )
        }
      </Modal>
    </div>
  );
}

export default App;
